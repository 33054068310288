<template>
  <div>
    <b-button variant="outline-primary" pill @click="isModalShow=true">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Add Topic</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new milestone"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="Type"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Type"
              label-for="h-item-type"
            >
              <b-form-select 
                v-model="topicData.type"
                :options="topicTypeOptions"
                :state="getValidationState(validationContext)"
                aria-describedby="input-type-feedback"
              />
              <b-form-invalid-feedback id="input-type-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
        
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import service from '../../../breaking_topic/service'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    lesson_id: { type: String, required: true },
    step_key: { type: String, required: true },
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      topicData: {
        type: null,
      },
      topicTypeOptions:[
        {text: "Từ vựng chủ đạo", value: 1},
        {text: "Luyện tập nghe ngấm", value: 2},
        {text: "Luyện tập", value: 3},
        {text: "Luyện tập phát âm cùng AI", value: 4},
        {text: "Luyện kỹ năng nói đuổi chuyên sâu", value: 5},
        {text: "Nói đuổi nâng cao cùng AI", value: 6},
        {text: "Phản xạ kỹ năng chuyên sâu", value: 7},
      ]
    }
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true
        let new_item = await service.create({ lesson_id: this.lesson_id, step_key: this.step_key, display_order: this.display_order, type: this.topicData.type })
        this.isLoading = false;
        if (new_item) {
          this.$router.push({ name: 'breaking-topic-detail', params: { topic_id: new_item._id } })
        }
      }
      this.resetTopicData();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    cancel(){
      this.isModalShow = false;
      this.resetTopicData();
    },
    resetTopicData(){
      this.topicData = {
        type: null
      }
    }
  },
}
</script>
