<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-start">
        <template v-if="!is_editting">
          <h2>{{ step.name }}</h2>
          <b-button variant="warning" class="btn-icon ml-1" size="sm"
            @click="is_editting = true"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

        <template v-else>
          <b-form-input v-model="step.name" />
          <update-button @update="is_editting = false" class="ml-50" size="md" />
        </template>
      </div>
      <!-- <create-lesson-form :step="category" /> -->
    </div>
    <slot />
  </b-card>
</template>

<script>

export default {
  components: {
  },
  props: {
    step: { type: Object, required: true },
  },
  data() {
    return {
      is_editting: false,
    }
  },
}
</script>
